<template>
    <custom-header :active="'mitglied'" style="position: sticky"></custom-header>
    <main>
        <section class="background-mitgliedwerden flex items-center text-center"></section>
        <section class="flex flex-col justify-center items-center mx-20-vh" style="margin-bottom: 5vw">
            <form class="items-center flex-col gap-24 flex-wrap flex gap-4 w-full">
                <div class="flex flex-row gap-8 w-full">
                    <div class="w-full">
                        <div class="mb-2 flex flex-col gap-4">
                            <label for="fname" class="p-4 bg-gray text-white w-fit">Mitgliedantrag</label>
                            <a class="btn" style="background-color: #3b5b90; color: white" href="https://cloud.henrikdev.xyz/Mitgliedsantrag_Happy_Dogs.pdf" download>
                                Download
                            </a>
                        </div>
                        <embed src="https://cloud.henrikdev.xyz/Mitgliedsantrag_Happy_Dogs.pdf" type="application/pdf" width="100%" style="min-height: 50vw" />
                    </div>
                    <div class="w-full">
                        <div class="mb-2 flex flex-col gap-4">
                            <label for="fname" class="p-4 bg-gray text-white w-fit">Satzung</label>
                            <a class="btn" style="background-color: #3b5b90; color: white" href="https://cloud.henrikdev.xyz/Satzung_Happy_Dogs.pdf" download>
                                Download
                            </a>
                        </div>

                        <embed src="https://cloud.henrikdev.xyz/Satzung_Happy_Dogs.pdf" type="application/pdf" width="100%" style="min-height: 50vw" />
                    </div>
                </div>
            </form>
        </section>
    </main>
    <custom-footer></custom-footer>
</template>

<style>
.background-mitgliedwerden {
    background-image: url('@/assets/images/MitgliedWerdenSeiteHeaderohneschatten.png');
    background-repeat: no-repeat;
    height: 725px;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    z-index: 2;
}
</style>

<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';

export default {
    name: 'Home',
    components: {
        CustomHeader,
        CustomFooter,
    },
    methods: {
        /*inputclick(event) {
            console.log(event);
            anime({
                targets: event.target,
                width: ['0%', '100%'],
                duration: 1000,
                borderBottom: '10px solid #ff4654',
                bottom: 0,
                opacity: 1,
                easing: 'easeInOutQuad',
            });
        },
        inputleave(event) {
            anime({
                targets: event.target,
                width: ['100%', '0%'],
                duration: 1000,
                borderBottom: '10px solid #ff4654',
                bottom: 0,
                opacity: 1,
                easing: 'easeInOutQuad',
            });
        },*/
    },
};
</script>
