<template class="h-screen">
    <custom-header :active="'moreinfo'" style="position: sticky"></custom-header>
    <main class="h-full">
        <section class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto grid_border my-2 text-white h-full items-center justify-center" style="background: #435c73">
            <section class="flex text-start">
                <div class="ml-36 mt-24 mb-12">
                    <p class="text-3xl p-4 bg-gray text-white w-fit mb-2">Kontakt</p>
                </div>
            </section>
            <section class="flex text-start flex-wrap flex-col ml-36" style="margin-bottom: 5vw">
                <div class="flex text-start flex-col gap-24 flex-wrap">
                    <div class="flex flex-col">
                        <p class="mb-2 text-l">Adresse: Rudolf-Mößner-Strasse 8, 74635 Kupferzell</p>
                        <p class="mb-2 text-l">Telefonnummer: "s"</p>
                        <p class="mb-2 text-l">Email: <a href="mailto:info@happydogs-kupferzell.de" style="color: #83b1db">info@happydogs-kupferzell.de</a></p>
                    </div>
                </div>
            </section>
        </section>
    </main>
    <custom-footer style="position: fixed; width: 100%; bottom: 0"></custom-footer>
</template>

<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';
export default {
    name: 'Home',
    components: {
        CustomHeader,
        CustomFooter,
    },
};
</script>
