<template>
    <custom-header style="position: sticky"></custom-header>
    <main>
        <section class="flex text-start">
            <div class="ml-36 mt-24 mb-12">
                <p class="text-1-2-xl">Datenschutzerklärung</p>
            </div>
        </section>
        <section class="flex text-start flex-direction-column flex-wrap ml-36 mr-2" style="margin-bottom: 5vw; margin-right: 5vw">
            <div class="flex flex-direction-column mb-12">
                <p class="mb-2 text-l">Datenschutz</p>
                <p class="mb-2 text-m">Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst</p>
                <p class="mb-2 text-m">
                    Der Schutz Ihrer Privatsphäre bei der Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten ist für uns ein wichtiges Anliegen, das wir in
                    der Mitgliederverwaltung und -betreuung sowie bei unseren sonstigen Geschäftsprozessen im Verein berücksichtigen.
                </p>
            </div>
            <p class="mb-4 text-l">A. Datenschutzerklärung für die Nutzung dieser Webseite</p>
            <div class="flex flex-direction-column mb-8">
                <p class="mb-4 text-ml">1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen</p>
                <p class="mb-4 text-m">Diese Datenschutzhinweise gelten für die Datenverarbeitung durch:</p>
                <p class="mb-2 text-m">Verantwortlicher: Happy Dogs e.V. Kupferzell, 1. Vorsitzender: Kevin Treff</p>
                <p class="mb-2 text-m">Anschrift: Rudolf-Mößner-Strasse 8, 74635 Kupferzell</p>
                <a class="mb-2 text-m" href="happydogs-kupferzell.de">happydogs-kupferzell.de</a>
                <a class="mb-2 text-m" href="mailto:info@happydogs-kupferzell.de">info@happydogs-kupferzell.de</a>
            </div>
            <div class="flex flex-direction-column mb-8">
                <p class="mb-4 text-ml">2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</p>
                <p class="mb-4 text-m">
                    Beim Aufrufen unserer Website www.happydogs-kupferzell.de können durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen
                    an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei
                    ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
                </p>
                <ul class="block text-start pl-8 mb-4" style="list-style: disc">
                    <li style="list-style: disc">IP-Adresse des anfragenden Rechners</li>
                    <li style="list-style: disc">Datum und Uhrzeit des Zugriffs</li>
                    <li style="list-style: disc">Name und URL der abgerufenen Datei</li>
                    <li style="list-style: disc">Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
                    <li style="list-style: disc">verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>
                </ul>
                <p class="mb-4 text-m">
                    Oben genannte Punkte werden aktuell NICHT gespeichert. Können allerdings zum späteren Zeitpunkt ohne Ankündigung zur Analyse-Zwecken gespeichert
                    werden. In diesem Fall wird dieser Abschnitt aus der Datenschutz-Information entfernt.
                </p>
            </div>
        </section>
    </main>
    <custom-footer></custom-footer>
</template>

<style></style>

<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';
export default {
    name: 'Datenschutz',
    components: {
        CustomHeader,
        CustomFooter,
    },
};
</script>
