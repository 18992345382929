<template>
    <custom-header :active="'news'" class="w-full"></custom-header>
    <main>
        <section class="background-main flex items-center text-center"></section>
        <section class="flex items-center text-center justify-center">
            <div class="grid grid-main gap-8 w-full mx-30-vh">
                <div
                    v-for="card of cards"
                    :key="card"
                    class="m-h-50 grid_border p-12 bg-opacity"
                    :style="`background-image: url(${card.background}); background-size: cover`"
                >
                    <div class="h-full">
                        <div class="flex flex-row items-center justify-center gap-4">
                            <img :src="card.image" width="96" height="96" />
                            <p class="text-xl font-carter-one">{{ card.title }}</p>
                        </div>
                        <div class="flex flex-col items-center text-center justify-center h-full">
                            <p class="text-xl font-carter-one">{{ card.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <img src="@/assets/images/bilduntenohneschatten.png" style="width: 100%" />
        </section>
    </main>
    <custom-footer></custom-footer>
</template>
<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';
export default {
    name: 'News',
    components: {
        CustomHeader,
        CustomFooter,
    },
};
</script>
