<template>
    <custom-header :active="'home'" style="position: sticky"></custom-header>
    <main>
        <section class="background flex items-center text-center">
            <div class="center-image-main">
                <img src="@/assets/images/happydogslogo.png" width="400" />
                <div class="ml-36 mt-4">
                    <p class="">Dein Hundeverein für Freizeitaktivitäten,</p>
                    <p class="texthead2-neueseite">Hundeerziehung und Hundesport in Kupferzell.</p>
                </div>
            </div>
        </section>
        <section class="flex items-center text-center flex-wrap mt-16 flex-direction-column">
            <div class="element-left mb-12">
                <img src="@/assets/images/doghausiconblaurahmen.png" width="750" />
            </div>
            <div class="element-right">
                <img src="@/assets/images/doghausicongruenrahmen.png" width="750" />
            </div>
            <div class="element-left mb-12" style="margin-top: -2em">
                <img src="@/assets/images/doghausiconblaurahmen.png" width="750" />
            </div>
            <div class="element-right">
                <img src="@/assets/images/doghausicongruenrahmen.png" width="750" />
            </div>
        </section>
        <section style="min-height: 850px">
            <img src="@/assets/images/bilduntenohneschatten.png" width="100%" />
        </section>
    </main>
    <custom-footer></custom-footer>
</template>

<style src="@/assets/css/new_design.css"></style>
<style></style>

<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';
export default {
    name: 'Home',
    components: {
        CustomHeader,
        CustomFooter,
    },
};
</script>
