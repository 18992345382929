<template>
    <custom-header :active="'aboutus'" style="position: sticky"></custom-header>
    <main>
        <section class="background-hund-mensch flex items-center text-center">
            <div class="center-image-main"></div>
        </section>
        <section class="flex flex-col justify-center items-center" style="margin-bottom: 5vw; margin-top: 2.5vw">
            <div class="grid grid-main gap-8 w-full mx-30-vh" style="grid-template-rows: auto">
                <div v-for="element of matchings" :key="element" class="flex items-center text-center flex-col">
                    <img class="flex flex-direction-column mb-2" :src="element.image" :width="250" />
                    <p class="bg-gray text-white p-2">{{ element.name }}</p>
                </div>
            </div>
        </section>
    </main>
    <custom-footer></custom-footer>
</template>

<style>
.background-hund-mensch {
    background-image: url('@/assets/images/Hund-Mensch-TeamSeiteHeaderohneschatten.png');
    background-repeat: no-repeat;
    height: 725px;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    z-index: 2;
}
</style>

<script>
import CustomHeader from '../components/header.vue';
import CustomFooter from '../components/footer.vue';

export default {
    name: 'hundmensch',
    components: {
        CustomHeader,
        CustomFooter,
    },
    data() {
        return {
            matchings: [
                {
                    name: 'Kevin mit Maja',
                    image: 'https://cloud.henrikdev.xyz/hundeverein_kupferzell/galerie/IMG_20230617_015927.jpg',
                },
                {
                    name: 'Alisa mit Miko',
                    image: 'https://cloud.henrikdev.xyz/hundeverein_kupferzell/galerie/Screenshot_20230520_205802.jpg',
                },
                {
                    name: 'Jochen mit Miko',
                    image: 'https://cloud.henrikdev.xyz/hundeverein_kupferzell/galerie/IMG_20230626_135425.jpg',
                },
            ],
        };
    },
    methods: {
        upperCase(text) {
            let return_text = '';
            const split_string = text.split('');
            for (const split of split_string) {
                if (split === split.toUpperCase() && split != ' ') return_text += `<small class='upper'>${split}</small>`;
                else return_text += split;
            }
            return return_text;
        },
    },
};
</script>
