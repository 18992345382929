import {createRouter, createWebHistory} from 'vue-router';
import Home from '../views/Home.vue';
import AboutUs from '../views/AboutUs.vue';
import Images from '../views/Images.vue';
import Mitglied from '../views/Mitglied.vue';
import Kontakt from '../views/Kontakt.vue';
import Impressum from '../views/Impressum.vue';
import DatenSchutz from '../views/DatenSchutz.vue';
import HundMensch from '../views/HundMensch.vue';
import Vorstand from '../views/Vorstand.vue';
import News from '../views/News.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/news',
        name: 'news',
        component: News,
    },
    {
        path: '/über-uns',
        name: 'aboutus',
        component: AboutUs,
    },
    {
        path: '/galerie',
        name: 'images',
        component: Images,
    },
    {
        path: '/mitglied-werden',
        name: 'mitglied',
        component: Mitglied,
    },
    {
        path: '/kontakt',
        name: 'contact',
        component: Kontakt,
    },
    {
        path: '/impressum-datenschutz',
        name: 'impressum',
        component: Impressum,
    },
    {
        path: '/datenschutz',
        name: 'DatenSchutz',
        component: DatenSchutz,
    },
    {
        path: '/hund-mensch',
        name: 'hundmensch',
        component: HundMensch,
    },
    {
        path: '/vorstand',
        name: 'vorstand',
        component: Vorstand,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
